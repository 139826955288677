import _ from "lodash"
import store from "@/store"

//Global variables
let streams = []
import adapter from "webrtc-adapter"

//Variables to use in the quick scan
const quickScanList = [
  {
    label: "4K(UHD)",
    width: 3840,
    height: 2160,
    ratio: "16:9",
  },
  {
    label: "1080p(FHD)",
    width: 1920,
    height: 1080,
    ratio: "16:9",
  },
  {
    label: "UXGA",
    width: 1600,
    height: 1200,
    ratio: "4:3",
  },
  {
    label: "720p(HD)",
    width: 1280,
    height: 720,
    ratio: "16:9",
  },
  {
    label: "SVGA",
    width: 800,
    height: 600,
    ratio: "4:3",
  },
  {
    label: "VGA",
    width: 640,
    height: 480,
    ratio: "4:3",
  },
  {
    label: "360p(nHD)",
    width: 640,
    height: 360,
    ratio: "16:9",
  },
  {
    label: "CIF",
    width: 352,
    height: 288,
    ratio: "4:3",
  },
  {
    label: "QVGA",
    width: 320,
    height: 240,
    ratio: "4:3",
  },
  {
    label: "QCIF",
    width: 176,
    height: 144,
    ratio: "4:3",
  },
  {
    label: "QQVGA",
    width: 160,
    height: 120,
    ratio: "4:3",
  },
]

export const runResolutionScan = (data) => {
  return new Promise((resolve) => {
    // data.maxFrameRate = _.get(data.frameRate, 'max') || _.get(data.frameRate, 'min') || 30;
    data.maxFrameRate = 30
    runFrameTest(data).then((testResult) => {
      killAnyStream()
      resolve(testResult)
    })
  })
}

let runFrameTest = (data) => {
  return new Promise((resolve) => {
    let prmsArr = []
    let frameToCheck = data.maxFrameRate
    for (let i = 0; i < quickScanList.length; i++) {
      let candidate = quickScanList[i]
      let wrongResultResponse = {
        candidate: candidate,
        frameRate: frameToCheck,
        valid: false,
      }
      let timeoutUserMedia = (i + 1) * 200
      let timeoutFallBack = (i + 1) * 1500
      let cleanGum = gum(candidate, data.device, frameToCheck, timeoutUserMedia)
      let gumPromise =
        adapter.browserDetails.browser === "chrome"
          ? promiseTimeout(timeoutFallBack, cleanGum, wrongResultResponse)
          : cleanGum
      prmsArr.push(gumPromise)
    }
    Promise.all(prmsArr).then((values) => {
      let findedValid = _.find(values, { valid: true })
      if (findedValid) {
        resolve(findedValid)
      } else {
        resolve(null)
      }
      // @todo implement better scan of frame rate
      // } else if (frameToCheck > 0) {
      //   frameToCheck = frameToCheck - 1;
      //   runFrameTest(frameToCheck)
    })
  })
}

//calls getUserMedia for a given camera and constraints
export const gum = (candidate, device, frameRate, timeoutUserMedia = 200) => {
  return new Promise((resolve) => {
    // console.log("trying " + candidate.label + " on " + device.text + "frameRate: " + frameRate);

    killAnyStream()

    //create constraints object
    // let constraints = {
    //   audio: false,
    //   video: {
    //     width: {exact: candidate.width},    //new syntax
    //     height: {exact: candidate.height},   //new syntax
    //     frameRate: frameRate
    //   }
    // };

    // if (device && device.deviceId) {
    //   _.set(constraints, 'video.deviceId.exact', device.deviceId)
    // }

    if (_.get(device, "deviceId")) {
      let constraints = _.cloneDeep(store.getters.getMediaConstraints)
      _.set(constraints, "video.width.exact", candidate.width)
      _.set(constraints, "video.height.exact", candidate.height)
      _.set(constraints, "video.deviceId.exact", device.deviceId)
      _.set(constraints, "audio", false)

      delete constraints.video.frameRate
      setTimeout(() => {
        var prmsUsermedia = navigator.mediaDevices.getUserMedia(constraints)
        /* eslint-disable-next-line */
        prmsUsermedia.then(gotStream).catch((error) => {
          // console.log('Display size for getUserMedia error!', error, device, constraints);
          resolve({
            candidate: candidate,
            frameRate: frameRate,
            valid: false,
          })
        })
      }, timeoutUserMedia) //official examples had this at 200
    } else {
      resolve({
        candidate: candidate,
        frameRate: frameRate,
        valid: false,
      })
    }

    function gotStream(mediaStream) {
      //change the video dimensions
      // console.log("Display size for " + candidate.label + ": " + candidate.width + "x" + candidate.height, candidate);
      streams.push(mediaStream) // make globally available
      resolve({
        candidate: candidate,
        frameRate: frameRate,
        valid: true,
      })
    }
  })
}

/**
 * Promise as fallback after some timeoout in case when pending promise not return result
 *
 * @param ms
 * @param promise
 * @param wrongResultResponse
 * @return {Promise<any>}
 */
const promiseTimeout = function (ms, promise, wrongResultResponse) {
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve) => {
    let id = setTimeout(() => {
      clearTimeout(id)
      resolve(wrongResultResponse)
    }, ms)
  })

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout])
}

let killAnyStream = () => {
  //Kill any running streams;
  try {
    if (streams.length > 0) {
      _.forEach(streams, (stream) => {
        stream.getTracks().forEach((track) => {
          track.stop()
        })
      })
      streams = []
    }
  } catch (err) {
    console.log("Error on killAnyStream", err)
  }
}
