let archetype = require("../../../assets/js/archetype/archetype.js")
/* eslint-disable-next-line */
import adapter from "webrtc-adapter"

export const getUserMediaSuitable = () => {
  /**
   * workaround for all browsers (fix done especially made for chrome),
   * on error getUserMedia return OverconstrainedError with field "constraint" that is undefined
   * (it is used to understand error type), we switch to native getUserMedia, avoiding getUserMedia from webrtc-adapter
   */
  // @todo maybe later do only for chrome (need more tests)
  // return (adapter.browserDetails.browser === 'chrome') ?
  //   archetype.getNativeMethod("window.navigator.mediaDevices.getUserMedia") :
  //   navigator.mediaDevices.getUserMedia
  return archetype.getNativeMethod("window.navigator.mediaDevices.getUserMedia")
}

export default { getUserMediaSuitable }
