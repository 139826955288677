import WebrtcCommon from "./webrtc-common"
import store from "@/store"
import { WEBRTC_CONNECTION_TYPE_SENDER } from "./constants"
import { runResolutionScan } from "@/common/webrtc/resolution-scan.service"
import { emptyHtmlElm } from "@/common/general.service"
import adapter from "webrtc-adapter"
import Sdp from "./sdp"
import { getUserMediaSuitable } from "./helpers/get-usermedia-suitable"
import _ from "lodash"

export default class WebrtcSender extends WebrtcCommon {
  audioCtx = null
  gainNode = null
  source = null
  dst = null
  volume = "1.0"
  mute = false
  originalStream = null

  /**
   * Info about previous and current started local stream
   * @type {{stream: null[], video: null[], audio: null[]}}
   */
  historyStartStream = {
    mediaConstraints: [null, null],
    stream: [null, null],
    allStreams: [],
  }

  constructor(props) {
    super(props)
    this.type = WEBRTC_CONNECTION_TYPE_SENDER
  }

  get selectedWebrtcVideoSource() {
    return store.getters.getSelectedVideoSourceWebengine
  }

  get selectedObjectVideoSource() {
    return store.getters.getSelectedObjectVideoSourceWebengine
  }

  get selectedWebrtcAudioSource() {
    return store.getters.getSelectedAudioSourceWebengine
  }

  get mediaConstraints() {
    return store.getters.getMediaConstraints
  }

  get formMediaConstraints() {
    return store.getters.getFormMediaConstraints
  }

  get audioGain() {
    return store.getters.getLocalStreamAudioGain
  }

  get localIsMuted() {
    return store.getters.getLocalStreamIsMuted
  }

  get currentScannedConstraints() {
    return store.getters.getCurrentScannedConstraints
  }

  get peerEncodersParams() {
    return store.getters.peerEncodersParams
  }

  get selectedResolutionWebengine() {
    return store.getters.getSelectedResolutionWebengine
  }

  get unchangedScannedConstraintsList() {
    return store.getters.unchangedScannedConstraintsList
  }

  get doStreamRestart() {
    return store.getters.doStreamRestart
  }

  get localVideoStarted() {
    return store.getters.localVideoStarted
  }

  get excludeSenderParams() {
    return store.getters.excludeSenderParams
  }

  stopAllStreams() {
    let self = this
    return new Promise((resolve) => {
      Promise.all([
        self.closeVideoCall(),
        self.stopCapturing(),
        self.stopAllHistoryTracks(),
      ]).then(() => {
        resolve()
      })
    })
  }

  resolutionInit() {
    let self = this
    return new Promise((resolve) => {
      let oVideoSource = self.selectedObjectVideoSource
      if (self.currentScannedConstraints) {
        resolve()
      } else {
        runResolutionScan({
          device: oVideoSource,
          frameRate: _.get(self.mediaConstraints, "video.frameRate"),
        }).then((bestResolution) => {
          console.log("bestResolution", oVideoSource, bestResolution)
          let prmsises = []
          if (bestResolution) {
            let setWH = store.dispatch("saveScannedConstraints", {
              deviceId: oVideoSource.deviceId,
              width: bestResolution.candidate.width,
              height: bestResolution.candidate.height,
              frameRate: bestResolution.frameRate,
            })
            prmsises.push(setWH)
          }
          Promise.all(prmsises).then(() => {
            resolve()
          })
        })
      }
    })
  }

  handleGetUserMediaError(e) {
    console.error("error on getUserMedia", e, JSON.stringify(e))
    let constraint = e.constraint || e.constraintName || null
    let message = "Your stream cannot be started."
    /**
     * First we check if selected resolution is greater than scanned
     */
    let selectedVideoDeviceId = this.mediaConstraints?.video?.deviceId?.exact
    let selectedResWidth = this.mediaConstraints?.video?.width?.exact
    let selectedResHeight = this.mediaConstraints?.video?.height?.exact
    if (selectedVideoDeviceId && selectedResWidth && selectedResHeight) {
      let findedScann = this.unchangedScannedConstraintsList.find(
        (c) => c.deviceId === selectedVideoDeviceId
      )
      if (findedScann) {
        let scanedResWidth = findedScann?.width
        let scanedResHeight = findedScann?.height
        if (
          scanedResWidth &&
          scanedResHeight &&
          (selectedResWidth > scanedResWidth ||
            selectedResHeight > scanedResHeight)
        ) {
          constraint = "width"
        }
      }
    }

    /**
     * Second check returned by returned error API object, field constraint
     */
    if (constraint) {
      if (constraint == "width" || constraint == "height") {
        message = `${message} Unsuported video quality for selected video source.`
      } else if (constraint == "deviceId") {
        message = `${message} Some of selected source device is inactive.`
      }
    }
    return {
      msg: message,
    }
  }

  /* eslint-disable-next-line */
  startMyWebcamera(checkResolution = true) {
    let self = this
    return new Promise((resolve, reject) => {
      if (self.localStream && self.doStreamRestart) {
        reject("You can't start a call because you already have one open!")
      } else {
        // For speed test do not check best resolution
        // let pCheckResolution = (self.doCheckResolution && self.props.refComponent.$options.name == 'run-test-block' || !self.mediaConstraints.video) ? Promise.resolve() : self.resolutionInit();
        // pCheckResolution.then(() => {
        console.log("constraints--mediaConstraints", self.mediaConstraints)
        setTimeout(() => {
          let getUserMedia = getUserMediaSuitable()
          getUserMedia(self.mediaConstraints)
            .then(function (localStream) {
              // /**
              // * In case when we want control audio volume through AudioContext use commented code bellow
              // */
              // let newStream = null;
              // if (localStream) {
              //   self.originalStream = localStream;
              //   newStream = new MediaStream();
              //   if (self.mediaConstraints.audio) {
              //     let aTracks = self.modifyGain(localStream, self.audioGain);
              //     if (aTracks) {
              //       // var filteredTrack = aTracks.getAudioTracks()[0];
              //       // newStream.addTrack(filteredTrack);
              //       _.forEach(aTracks.getAudioTracks(), function (aTrack) {
              //         newStream.addTrack(aTrack);
              //       });
              //     }
              //   }
              //   _.forEach(localStream.getVideoTracks(), function (vTrack) {
              //     newStream.addTrack(vTrack);
              //   });
              // }
              return localStream
            })
            .catch((err) => {
              reject(self.handleGetUserMediaError(err))
            })
            .then(function (localStream) {
              self.localStream = localStream
              if (self.localStream) {
                self.addVideoStream(self.localStream, "local")
                store.dispatch("saveIsLocalStreamStarted", true).then(() => {
                  self.getOrCreateConnection().then((conn) => {
                    self
                      .getOrCreatePeerConnection(conn.id, "local")
                      .then(() => {
                        // later remove this FROM
                        let vTracks = self.localStream.getVideoTracks()
                        if (vTracks.length > 0) {
                          let vTrack = self.localStream.getVideoTracks()[0]
                          let videoSettings = vTrack.getSettings()
                          if (videoSettings) {
                            store.dispatch("saveVideoResolution", {
                              width: videoSettings.width,
                              height: videoSettings.height,
                            })
                          }
                          // console.log('vTrack.getSettings()', vTrack.getSettings());
                        } else {
                          console.log("there are no vTrack.getSettings()")
                        }
                        // set bandwidth restriction
                        self.onBandwidthChange(self.props.bandwidth)
                        self.updateStreamVolume()
                        self.setPeerParams()
                        resolve(localStream)
                      })
                  })
                })
              } else {
                resolve()
              }
            })
        }, 1000)
        // });
      }
    })
  }

  initMyWebcameraParams() {}

  startFileStream(calleId) {
    let self = this
    //self.addVideoStream(self.localStream, 'local');
    store.dispatch("saveIsLocalStreamStarted", true)
    self.createPeerConnection(self.connection.id, "local", calleId)
    //self.peerAddTrack(localPeer);
  }

  // peerAddTrack(localPeer) {
  //     let self = this;
  //     if (self.localStream) {
  //         if (self.hasAddTrack) {
  //             self.localStream.getTracks().forEach(track => localPeer.pc.addTrack(track, self.localStream));
  //         } else {
  //             localPeer.pc.addStream(self.localStream);
  //         }
  //     }
  // },

  modifyGain(stream, gainValue) {
    let self = this
    var AudioContext =
      window.AudioContext || // Default
      window.webkitAudioContext || // Safari and old versions of Chrome
      false
    if (AudioContext) {
      self.audioCtx = new AudioContext()
      if (self.audioCtx) {
        self.source = self.audioCtx.createMediaStreamSource(stream)
        self.dst = self.audioCtx.createMediaStreamDestination()
        self.gainNode = self.audioCtx.createGain()
        self.gainNode.gain.value = gainValue
        ;[self.source, self.gainNode, self.dst].reduce(
          (a, b) => a && a.connect(b)
        )
      }
    } else {
      // Web Audio API is not supported
      // Alert the user
      console.log(
        "Sorry, but the Web Audio API is not supported by your browser. Please, consider upgrading to the latest version or downloading Google Chrome or Mozilla Firefox"
      )
    }
    return self.dst ? self.dst.stream : null
  }

  updateStreamVolume() {
    let self = this
    // in case when we use AudioContext
    if (this.gainNode) {
      this.gainNode.gain.value = parseFloat(this.audioGain)
    }
    // in case when we direct mute MediaStreamTrack
    if (self.localStream) {
      self.localStream
        .getTracks()
        .filter((track) => track.kind === "audio")
        .forEach((track) => {
          track.enabled = self.audioGain === 0 ? false : true
        })
    }
  }

  stopAndRemoveTrack(mediaStream) {
    return function (track) {
      track.stop()
      mediaStream.removeTrack(track)
    }
  }

  stopMediaStream(mediaStream) {
    let self = this
    if (!mediaStream) {
      return
    }
    ;[self.source, self.gainNode, self.dst].reduce(
      (a, b) => a && a.disconnect(b)
    )
    if (self.audioCtx) self.audioCtx.close()
    self.audioCtx = null
    self.gainNode = null
    self.source = null
    self.dst = null
    mediaStream.getTracks().forEach(self.stopAndRemoveTrack(mediaStream))
    if (self.originalStream) {
      self.originalStream
        .getTracks()
        .forEach(self.stopAndRemoveTrack(self.originalStream))
    }
  }

  // Close the RTCPeerConnection and reset variables so that the user can
  // make or receive another call if they wish. This is called both
  // when the user hangs up, the other user hangs up, or if a connection
  // failure is detected.

  closeVideoCall(closeConnection = true) {
    let self = this
    return new Promise((resolve, reject) => {
      self
        .closeAllPeerConnections()
        .then(() => {
          try {
            var localVideo = document.getElementById("local_video")
            if (localVideo) {
              if (self.localVideoStarted) {
                localVideo.pause()
              }

              // Stop the videos
              if (localVideo.srcObject) {
                localVideo.srcObject
                  .getTracks()
                  .forEach((track) => track.stop())
                try {
                  console.log("localVideo.srcObject", localVideo.srcObject)
                  // localVideo.srcObject = null;
                } catch (err2) {
                  console.log(err2)
                }
              }
              if (self.localVideoStarted) {
                localVideo.src = null
              }
            }

            let container = this.getStreamContainer()
            emptyHtmlElm(container)

            self.stopMediaStream(self.localStream)
            self.localStream = null
            self.stopCapturing()
            store.dispatch("saveIsLocalStreamStarted", false).then(() => {
              if (closeConnection) {
                self.disconnect()
              }
              resolve()
            })
          } catch (err) {
            reject(err)
          }
        })
        .catch((err) => reject(err))
    })
  }

  async startScreenCapture() {
    // let constraintsScreen = {
    //     video: {
    //         width: 1920,
    //         height: 1080,
    //         mandatory: {
    //             minWidth: 1920,
    //             maxWidth: 1920,
    //             minHeight: 1080,
    //             maxHeight: 1080
    //         },
    //     },
    //     audio:true
    // };

    let constraintsScreen

    if (!this.excludeSenderParams) {
      constraintsScreen = {
        video: {
          width: 1920,
          height: 1080,
          // mandatory: {
          //         //   minWidth: 1920,
          //         //   maxWidth: 1920,
          //         //   minHeight: 1080,
          //         //   maxHeight: 1080
          //         // },
          // frameRate: _.get(this.mediaConstraints, 'video.frameRate.max') || {},
          //deviceId: {exact: [this.mediaConstraints.audio]},
          mediaStreamSource: { exact: ["desktop"] },
        },
      }
      let frameRate = _.get(this.mediaConstraints, "video.frameRate.max")
      if (frameRate) {
        constraintsScreen.video.frameRate = frameRate
      }
    } else {
      constraintsScreen = {
        video: {
          mediaStreamSource: { exact: ["desktop"] },
        },
      }
    }

    console.log("constraints--constraintsScreen--video", constraintsScreen)

    // let constraintsScreen = {
    //     // video: {
    //     //     width: 1920,
    //     //     height: 1080,
    //     //     mandatory: {
    //     //         minWidth: 1920,
    //     //         maxWidth: 1920,
    //     //         minHeight: 1080,
    //     //         maxHeight: 1080
    //     //     },
    //     // },
    //     //audio: this.mediaConstraints.audio
    //      video: {
    //             width: 200,
    //             height: 100,
    //             mandatory: {
    //                 minWidth: 200,
    //                 maxWidth: 200,
    //                 minHeight: 100,
    //                 maxHeight: 100
    //             },
    //         },
    //     audio: true
    //     // advanced: {
    //     //      video: {
    //     //         width: 200,
    //     //         height: 100,
    //     //         mandatory: {
    //     //             minWidth: 200,
    //     //             maxWidth: 200,
    //     //             minHeight: 100,
    //     //             maxHeight: 100
    //     //         },
    //     //     },
    //     //     audio: this.mediaConstraints.audio
    //     // }
    // };

    if (navigator.getDisplayMedia) {
      return navigator.getDisplayMedia(constraintsScreen)
    } else if (navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(constraintsScreen)
    } else {
      return navigator.mediaDevices.getUserMedia({
        video: { mediaSource: "screen" },
      })
    }
  }

  /* eslint-disable-next-line */
  async startCapturing(e) {
    let self = this
    let stream = await self.startScreenCapture()
    return new Promise((resolve, reject) => {
      self.screen_capture.status = "Screen recording started."
      self.screen_capture.enableStartCapture = false
      self.screen_capture.enableStopCapture = true
      self.screen_capture.enableDownloadRecording = false

      if (self.screen_capture.recording) {
        window.URL.revokeObjectURL(self.screen_capture.recording)
      }

      self.screen_capture.chunks = []
      self.screen_capture.recording = null
      self.screen_capture.stream = stream

      self.localStream = self.screen_capture.stream

      self.localStream.addEventListener("inactive", (e) => {
        console.log("Capture stream inactive - stop recording!")
        store.dispatch("saveIsLocalStreamStarted", false)
        self.stopCapturing(e)
      })

      if (self.mediaConstraints.audio) {
        console.log(
          "constraints--constraintsScreen--audio",
          self.mediaConstraints.audio
        )
        navigator.mediaDevices
          .getUserMedia({ video: false, audio: self.mediaConstraints.audio })
          .then(function (audioStream) {
            var audioTrack = audioStream.getAudioTracks()[0]
            self.localStream.addTrack(audioTrack)
          })
          .catch((error) => {
            reject(error)
          })
      }

      self.addVideoStream(self.localStream, "local")

      store.dispatch("saveIsLocalStreamStarted", true).then(() => {
        resolve(self.localStream)
      })

      // self.screen_capture.mediaRecorder = new MediaRecorder(self.screen_capture.stream, {mimeType: 'video/webm'});
      // self.screen_capture.mediaRecorder.addEventListener('dataavailable', event => {
      //   if (event.data && event.data.size > 0) {
      //     self.screen_capture.chunks.push(event.data);
      //   }
      // });
      // self.screen_capture.mediaRecorder.start(10);
    })
  }

  /* eslint-disable-next-line */
  stopCapturing(e) {
    let self = this
    return new Promise((resolve) => {
      // self.screen_capture.mediaRecorder.stop();
      // self.screen_capture.mediaRecorder = null;
      if (!_.isEmpty(self.screen_capture)) {
        self.screen_capture.status = "Screen recorded completed."
        self.screen_capture.enableStartCapture = true
        self.screen_capture.enableStopCapture = false
        self.screen_capture.enableDownloadRecording = true
        if (!_.isEmpty(self.screen_capture.stream)) {
          self.screen_capture.stream
            .getTracks()
            .forEach((track) => track.stop())
          self.screen_capture.stream = null
        }
      }
      resolve()
      // self.closeVideoCall();
      // self.screen_capture.recording = window.URL.createObjectURL(new Blob(this.chunks, {type: 'video/webm'}));
    })
  }

  /* eslint-disable-next-line */
  downloadRecording(e) {
    console.log("Download recording.")
    this.enableStartCapture = true
    this.enableStopCapture = false
    this.enableDownloadRecording = false

    const downloadLink = this.shadowRoot.querySelector("a#downloadLink")
    downloadLink.addEventListener("progress", (e) => console.log(e))
    downloadLink.href = this.recording
    downloadLink.download = "screen-recording.webm"
    downloadLink.click()
  }

  getPeerByMsg(msg) {
    let self = this
    return new Promise((resolve) => {
      if (_.get(self.peers, [msg.from, "local"])) {
        resolve(_.get(self.peers, [msg.from, "local"]))
      } else if (_.get(self.peers, [msg.to, "local"])) {
        resolve(_.get(self.peers, [msg.to, "local"]))
      } else {
        self.getOrCreatePeerConnection(msg.to, "local").then((peer) => {
          resolve(peer)
        })
      }
    })
  }

  createPlainPeerConnection() {
    let self = this
    return new Promise((resolve, reject) => {
      super
        .createPlainPeerConnection()
        .then((peer) => {
          self.peerAddTrack(peer).then(() => {
            console.log("peerAddTrack")
            resolve(peer)
          })
        })
        .catch((err) => {
          reject(err)
          console.log("catch Errrrr createPlainPeerConnection sender", err)
        })
    })
  }

  setLocalStreamFromFile(stream) {
    let self = this
    self.localStream = stream
    store.dispatch("saveIsLocalStreamStarted", true)
    // self.createPlainPeerConnection().then(() => {
    //   store.dispatch('saveIsLocalStreamStarted', true).then(() => {
    //     self.onBandwidthChange(self.props.bandwidth)
    //   })
    // });
  }

  peerAddTrack(peer) {
    let self = this
    return new Promise((resolve) => {
      if (self.localStream && peer) {
        // if (self.hasAddTrack) {
        //     self.localStream.getTracks().forEach(track => self.peers[callerId]['local'].pc.addTrack(track, self.localStream));
        // } else {
        //     self.peers[callerId]['local'].pc.addStream(self.localStream);
        // }

        // self.localStream.getTracks().forEach((track) => {
        //   self.peers[callerId]['local'].pc.addTrack(track, self.localStream)
        // });
        try {
          self.localStream.getTracks().forEach((track) => {
            peer.pc.addTrack(track, self.localStream)
          })
        } catch (err) {
          console.error("err on peerAddTrack---", err)
        }
        resolve()
      } else {
        resolve()
        console.log(
          "peerAddTrack no self.localStream or no peer",
          self.localStream,
          peer
        )
      }
    })
  }

  // callback when bandwidth change
  onBandwidthChange(newVal) {
    let self = this
    if (!self.excludeSenderParams) {
      self.localPeers.forEach((peer) => {
        self.peerBandwidthChange(newVal, peer)
      })
    }
  }

  // bandwidth change for one peer
  peerBandwidthChange(newVal, peer) {
    let self = this
    return new Promise((resolve) => {
      if (peer && store.getters.getIsLocalStreamStarted) {
        // In Chrome, use RTCRtpSender.setParameters to change bandwidth without
        // (local) renegotiation. Note that this will be within the envelope of
        // the initial maximum bandwidth negotiated via SDP.
        if (
          (adapter.browserDetails.browser === "chrome" ||
            adapter.browserDetails.browser === "safari" ||
            (adapter.browserDetails.browser === "firefox" &&
              adapter.browserDetails.version >= 64)) &&
          "RTCRtpSender" in window &&
          "setParameters" in window.RTCRtpSender.prototype
        ) {
          try {
            peer.getSenders().forEach((sender) => {
              let kind = _.get(sender, "track.kind")
              if (kind && (kind === "video" || kind === "audio")) {
                const parameters = sender.getParameters()
                setTimeout(() => {
                  if (!parameters.encodings) {
                    parameters.encodings = [{}]
                  }
                  if (!newVal) {
                    delete parameters.encodings[0].maxBitrate
                  } else {
                    let maxBitrate = parseFloat(newVal) * 1000 * 1000
                    _.set(parameters, "encodings[0].maxBitrate", maxBitrate)
                    //parameters.encodings[0].maxBitrate = parseFloat(newVal) * 1000 * 1000;
                  }
                  sender
                    .setParameters(parameters)
                    .then(() => {
                      console.log("bandwidth changed first method with success")
                    })
                    .catch((e) => {
                      console.error(e)
                    })
                }, 50)
              }
            })
          } catch (err) {
            console.error("error on set bandwidth first method", err)
          }
          resolve()
          return
        }

        // Fallback to the SDP munging with local renegotiation way of limiting
        // the bandwidth.
        peer.pc
          .createOffer()
          .then((offer) => peer.pc.setLocalDescription(offer))
          .then(() => {
            const desc = {
              type: peer.pc.remoteDescription.type,
              sdp: !newVal
                ? Sdp.removeBandwidthRestriction(peer.pc.remoteDescription.sdp)
                : Sdp.updateBandwidthRestriction(
                    peer.pc.remoteDescription.sdp,
                    self.bandwidthKilobits
                  ),
            }
            // console.log('Applying bandwidth restriction to setRemoteDescription:\n' + desc.sdp);
            return peer.pc.setRemoteDescription(desc)
          })
          .then(() => {
            console.log("bandwidth changed second method with success")
            resolve()
          })
          .catch((e) => {
            resolve({ error: e })
            console.error(e)
            return
          })
      } else {
        resolve()
      }

      // OLD  when was in receiver
      // if (self.$options.name == "webengine-receiver") {
      //   self.getOrCreateConnection(false).then((rConn) => {
      //     let callerId = rConn.id;
      //     let remotePeer = _.get(self.peers, [callerId, 'remote']);
      //     let localPeer = _.get(self.peers, [callerId, 'local']);
      //     if (remotePeer) {
      //       const offerOptions = {
      //         offerToReceiveAudio: 1,
      //         offerToReceiveVideo: 1
      //       };
      //       remotePeer.pc.createOffer(offerOptions)
      //         .then((offer) => {
      //           return remotePeer.pc.setLocalDescription(offer);
      //         })
      //         .then(() => {
      //           // // @todo maybe later set bandwidth for audio
      //           // let changedSdp = Sdp.setMediaBitrate(Sdp.setMediaBitrate(localPeer.pc.remoteDescription.sdp, "video", newVal), "audio", 100);
      //           let lLocalDesc = localPeer.pc.remoteDescription;
      //           const desc = {
      //             type: lLocalDesc.type,
      //             sdp: !newVal
      //               ? Sdp.removeBandwidthRestriction(lLocalDesc.sdp)
      //               : Sdp.setMediaBitrate(lLocalDesc.sdp, "video", self.bandwidthKilobits)
      //           };
      //           // console.log('Applying bandwidth restriction to setRemoteDescription:\n' , desc);
      //           return localPeer.pc.setRemoteDescription(desc);
      //         })
      //         .then(() => {
      //           // bandwidthSelector.disabled = false;
      //         })
      //         .catch(self.onSetSessionDescriptionError);
      //     }
      //   });
      // }
    })
  }

  /**
   * Set RTCRtpSender parameters
   */
  setPeerParams() {
    let self = this
    if (!self.excludeSenderParams) {
      self.localPeers.forEach((peer) => {
        if (peer && store.getters.getIsLocalStreamStarted) {
          if (
            "RTCRtpSender" in window &&
            "setParameters" in window.RTCRtpSender.prototype
          ) {
            peer.getSenders().forEach((sender) => {
              let kind = _.get(sender, "track.kind")
              if (kind === "video" || kind === "audio") {
                const parameters = sender.getParameters()
                setTimeout(() => {
                  /**
                   * Specifies the preferred way the WebRTC layer should handle optimizing bandwidth
                   * against quality in constrained-bandwidth situations;
                   * Degrade framerate in order to maintain resolution
                   */
                  _.set(
                    parameters,
                    "degradationPreference",
                    "maintain-resolution"
                  )

                  /**
                   * Set encodings params
                   */
                  if (!parameters.encodings) {
                    parameters.encodings = [{}]
                  }
                  // @todo maybe later move peerEncodersParam in props for WebrtcSenderWebengine class
                  for (let param in self.peerEncodersParams) {
                    let value = self.peerEncodersParams[param].val
                    let kinds = self.peerEncodersParams[param].kinds
                    if (kinds.includes(kind)) {
                      if (!value) {
                        delete parameters.encodings[0][param]
                      } else {
                        _.set(parameters, `encodings[0].${param}`, value)
                      }
                    }
                  }

                  sender
                    .setParameters(parameters)
                    .then(() => {
                      console.log("setPeerParams changed to done with success")
                    })
                    .catch((e) => {
                      console.error("setPeerParams error", e)
                    })
                }, 50)
              }
            })
          }
        }
      })
    }
  }

  /**
   * Start local stream in mode with or without restart stream on change source
   */
  modeStartLocalStream(selectedVideoSource) {
    let self = this
    return new Promise((resolve, reject) => {
      if (self.doStreamRestart) {
        self
          .startLocalStream(selectedVideoSource)
          .then((localStream) => {
            resolve(localStream)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        // // stop old streams before use new ones
        // let newVideoDeviceId = self.mediaConstraints?.video?.deviceId?.exact
        // let prevVideoDeviceId = self.historyStartStream.mediaConstraints[1]?.video?.deviceId?.exact
        // if (prevVideoDeviceId === 'screencapture') {
        //   var localVideo = document.getElementById("local_video")
        //   if (localVideo) {
        //     localVideo.pause()
        //     // Stop the videos
        //     if (localVideo.srcObject) {
        //       console.log('localVideo.srcObject.getTracks()', localVideo.srcObject.getTracks())
        //       localVideo.srcObject.getTracks().forEach(track => track.stop())
        //       try {
        //         console.log('localVideo.srcObject', localVideo.srcObject)
        //         // localVideo.srcObject = null;
        //       } catch (err2) {
        //         console.log(err2)
        //       }
        //     }
        //     // localVideo.src = null;
        //   }
        //   self.stopMediaStream(self.localStream)
        //   self.stopCapturing()
        // } else if (newVideoDeviceId === 'screencapture' && prevVideoDeviceId) {
        //   self.stopMediaStream(self.localStream)
        // }

        // fix for firefox because it cannot accept simoultanesly 2 audio or video tracks
        let newAudioDeviceId = self.mediaConstraints?.audio?.deviceId?.exact
        let prevAudioDeviceId =
          self.historyStartStream.mediaConstraints[1]?.audio?.deviceId?.exact
        let audioDeviceHasChanged = newAudioDeviceId !== prevAudioDeviceId
        let audioStopped = false
        if (
          adapter.browserDetails.browser === "firefox" &&
          prevAudioDeviceId &&
          audioDeviceHasChanged
        ) {
          self.stopAllHistoryTracks("audio")
          audioStopped = true
          console.log(
            "fix for firefox because it cannot accept simoultanesly 2 audio or video tracks",
            self.historyStartStream.stream
          )
          // let prevStream = self.historyStartStream.stream[1]
          // if (prevStream) {
          //   prevStream.getAudioTracks().forEach((track) => {
          //     track.stop()
          //   })
          //   audioStopped = true
          // }
        }
        setTimeout(
          () => {
            self
              .startLocalStream(selectedVideoSource)
              .then((localStream) => {
                // write previous current streams
                self.historyStartStream.mediaConstraints[0] =
                  self.historyStartStream.mediaConstraints[1]
                self.historyStartStream.mediaConstraints[1] =
                  self.mediaConstraints
                self.historyStartStream.stream[0] =
                  self.historyStartStream.stream[1]
                self.historyStartStream.stream[1] = localStream
                self.historyStartStream.allStreams.push(localStream)

                self.replaceLocalTracks().then(() => {
                  resolve(localStream)
                })
              })
              .catch((err) => {
                reject(err)
              })
          },
          audioStopped ? 1000 : 10
        )
      }
    })
  }

  /**
   * Start local stream webcamera or screencapture and get in response
   */
  startLocalStream(selectedVideoSource) {
    let self = this
    return new Promise((resolve, reject) => {
      let prms =
        selectedVideoSource === "screencapture"
          ? self.startCapturing()
          : self.startMyWebcamera()
      prms
        .then((localStream) => {
          resolve(localStream)
        })
        .catch((error) => {
          console.error("Error startLocalStream", error)
          reject(error)
        })
    })
  }

  /**
   *  Logic of replace local media tracks to new ones in case of "Not restart stream" mode
   *
   * @returns {*}
   */
  replaceLocalTracks() {
    let self = this
    return new Promise((resolve) => {
      let newVideoDeviceId = self.mediaConstraints?.video?.deviceId?.exact
      let newAudioDeviceId = self.mediaConstraints?.audio?.deviceId?.exact
      let prevVideoDeviceId =
        self.historyStartStream.mediaConstraints[0]?.video?.deviceId?.exact
      let prevAudioDeviceId =
        self.historyStartStream.mediaConstraints[0]?.audio?.deviceId?.exact
      let videoDeviceChanged = newVideoDeviceId !== prevVideoDeviceId
      let audioDeviceHasChanged = newAudioDeviceId !== prevAudioDeviceId

      let senderParams = {
        newVideoDeviceId,
        prevVideoDeviceId,
        videoDeviceChanged,
        newAudioDeviceId,
        prevAudioDeviceId,
        audioDeviceHasChanged,
      }

      let prms = []
      // if some device was changed (video/audio) we replace it in stream
      self.localPeers.forEach((peer) => {
        prms.push(self.trackInStreamChange({ peer, senderParams }))
      })

      Promise.all(prms).then(() => {
        resolve()
      })

      //   @todo maybe later will make functionality of constraint change on the fly
      //   let prms = []
      //   self.localStream.getVideoTracks().forEach((t) => {
      //     console.log('self.mediaConstraints.video', self.mediaConstraints.video)
      //     prms.push(t.applyConstraints(self.mediaConstraints.video))
      //   })
      //   self.localStream.getAudioTracks().forEach((t) => {
      //     console.log('self.mediaConstraints.audio', self.mediaConstraints.audio)
      //     prms.push(t.applyConstraints(self.mediaConstraints.audio))
      //   })
      //   Promise.all(prms).then(() => {
      //     resolve(self.localStream)
      //   }).catch((err) => {
      //     console.error('Error on applyConstraints', err)
      //     reject()
      //   })
    })
  }

  /**
   * Change video/audio track in stream for Sender Peer in case of "Not restart stream" mode
   *
   * @param params
   * @return {Promise<any>}
   */
  trackInStreamChange({ peer, senderParams }) {
    let self = this
    return new Promise((resolve, reject) => {
      if (peer && store.getters.getIsLocalStreamStarted) {
        if (
          "RTCRtpSender" in window &&
          "replaceTrack" in window.RTCRtpSender.prototype
        ) {
          try {
            let prms = []

            // video track replace handling
            if (senderParams.videoDeviceChanged) {
              let videoTracks = self.localStream.getVideoTracks()
              let videoTrack = (videoTracks && videoTracks[0]) || null
              let sender = peer.pc.getSenders().find(function (s) {
                return s.track && s.track.kind === "video"
              })
              if (
                senderParams.prevVideoDeviceId &&
                !senderParams.newVideoDeviceId
              ) {
                // if previously video source track was started but now we disable it
                //prms.push(peer.pc.removeTrack(sender))
                self.stopPreviousTrack("video", senderParams.prevVideoDeviceId)
              } else if (
                !senderParams.prevVideoDeviceId &&
                senderParams.newVideoDeviceId
              ) {
                // if previously video source track was stopped but now we enable it
                //prms.push(peer.pc.addTrack(videoTrack))
                if (!sender) {
                  self.localStream.getVideoTracks().forEach((track) => {
                    peer.pc.addTrack(track, self.localStream)
                  })
                }
                prms.push(sender.replaceTrack(videoTrack))
                self.stopPreviousTrack("video", senderParams.prevVideoDeviceId)
                console.log(
                  "22222---if previously video source track was stopped but now we enable it"
                )
              } else if (
                senderParams.prevVideoDeviceId &&
                senderParams.newVideoDeviceId
              ) {
                // in case when we change video source track
                if (!sender) {
                  self.localStream.getVideoTracks().forEach((track) => {
                    peer.pc.addTrack(track, self.localStream)
                  })
                }
                prms.push(sender.replaceTrack(videoTrack))
                self.stopPreviousTrack("video", senderParams.prevVideoDeviceId)
                console.log("33333---in case when we change video source track")
              }
            }

            console.log("trackInStreamChange--", senderParams)

            // audio track replace handling
            if (senderParams.audioDeviceHasChanged) {
              let audioTracks = self.localStream.getAudioTracks()
              let audioTrack = (audioTracks && audioTracks[0]) || null
              let sender = peer.pc.getSenders().find(function (s) {
                return s.track && s.track.kind === "audio"
              })
              if (
                senderParams.prevAudioDeviceId &&
                !senderParams.newAudioDeviceId
              ) {
                // if previously audio source track was started but now we disable it
                //prms.push(peer.pc.removeTrack(sender))
                self.stopPreviousTrack("audio")
                console.log(
                  "44444---if previously audio source track was started but now we disable it"
                )
              } else if (
                !senderParams.prevAudioDeviceId &&
                senderParams.newAudioDeviceId
              ) {
                // if previously audio source track was stopped but now we enable it
                console.log(
                  "55555---if previously audio source track was stopped but now we enable it"
                )
                if (!sender) {
                  self.localStream.getAudioTracks().forEach((track) => {
                    peer.pc.addTrack(track, self.localStream)
                  })
                }
                prms.push(sender.replaceTrack(audioTrack))
                self.stopPreviousTrack("audio")
              } else if (
                senderParams.prevAudioDeviceId &&
                senderParams.newAudioDeviceId
              ) {
                // in case when we change audio source track
                if (!sender) {
                  self.localStream.getAudioTracks().forEach((track) => {
                    peer.pc.addTrack(track, self.localStream)
                  })
                }
                prms.push(sender.replaceTrack(audioTrack))
                self.stopPreviousTrack("audio")
                console.log("66666---in case when we change audio source track")
              }
            }
            Promise.all(prms)
              .then(() => {
                resolve()
              })
              .catch((err) => {
                console.error("trackInStreamChange", err)
                reject()
              })
          } catch (err) {
            console.error("error replaceTrack", err)
            reject()
          }
        } else {
          console.error("RTCRtpSender or replaceTrack not supported in browser")
          reject()
        }
      } else {
        console.error(
          "error trackInStreamChange, no peer or local stream not started"
        )
        resolve()
      }
    })
  }

  /**
   * Stop previous started video/audio track, in case of "Not restart stream" mode
   */
  stopPreviousTrack(type = "video", prevVideoDeviceId) {
    let self = this
    if (self.historyStartStream.stream[0]) {
      if (type === "video") {
        self.historyStartStream.stream[0].getVideoTracks().forEach((track) => {
          track.stop()
        })
        if (prevVideoDeviceId === "screencapture") {
          self.stopCapturing()
        }
      } else if (type === "audio") {
        self.historyStartStream.stream[0].getAudioTracks().forEach((track) => {
          track.stop()
        })
      }
    }
  }

  /**
   * Stop all tracks from history, in case of "Not restart stream" mode
   */
  stopAllHistoryTracks(type = "all") {
    let self = this
    self.historyStartStream.allStreams.forEach((stream) => {
      if (stream) {
        let tracks = []
        if (type === "all") {
          tracks = stream.getTracks()
        } else if (type === "video") {
          tracks = stream.getVideoTracks()
        } else if (type === "audio") {
          tracks = stream.getAudioTracks()
        }
        tracks.forEach((track) => {
          track.stop()
        })
      }
    })
  }
}
